<template>
    <span v-if="remainingTime">
        <span v-if="days>2">{{ days }} days</span>
        <span v-else>
        {{ hours | formatTime }}:{{ minutes | formatTime }}:{{ seconds | formatTime }}
        </span>
    </span>
    <span v-else>Soon</span>
</template>

<script>
const TIMESTAMP_TO_MILI_TIMESTAMP = 1000;
export default {
    props: {
        deadlineString: {
            type: String,
        },
        deadlineTimestamp: {
            type: Number,
        },
        refreshPeriod: {
            type: Number,
            default: 1000,
        },
        countUpwards: {
            type: Boolean,
            default: false,
        }
    },
    data() {
        return {
            deadlineUnixTimestamp: 0,
            remainingTime: 0,
            timeoutId: 0,
        };
    },
    mounted() {
        if (this.deadlineString) {
            this.deadlineUnixTimestamp = Date.parse(this.deadlineString) / 1000;
        } else {
            this.deadlineUnixTimestamp = this.deadlineTimestamp;
        }

        this.remainingTime = this.calculateRemainingTime();
        this.timeoutId = setTimeout(this.countdown, 1000);
    },
    destroyed() {
        clearTimeout(this.timeoutId);
    },
    computed: {
        seconds() {
            return Math.floor((this.remainingTime / TIMESTAMP_TO_MILI_TIMESTAMP) % 60);
        },
        minutes() {
            return Math.floor((this.remainingTime / TIMESTAMP_TO_MILI_TIMESTAMP / 60) % 60);
        },
        hours() {
            return Math.floor((this.remainingTime / (TIMESTAMP_TO_MILI_TIMESTAMP * 60 * 60)));
        },
        days() {
            return Math.ceil(this.remainingTime / (TIMESTAMP_TO_MILI_TIMESTAMP * 60 * 60 * 24));
        }
    },
    filters: {
        formatTime(value) {
            if (value < 10) {
                return "0" + value;
            }
            return value;
        }
    },
    methods: {
        calculateRemainingTime() {
            const now = Date.parse(new Date());
            const givenTime = this.deadlineUnixTimestamp * TIMESTAMP_TO_MILI_TIMESTAMP;
            if (now > givenTime) {
                return this.countUpwards ? now - givenTime : 0;
            } else {
                return givenTime - now;
            }
        },
        countdown() {
            this.remainingTime = this.calculateRemainingTime();
            if (this.remainingTime > 0) {
                this.timeoutId = setTimeout(this.countdown, this.refreshPeriod);
            } else {
                this.remainingTime = null;
            }
        }
    }
}
</script>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.remainingTime
    ? _c("span", [
        _vm.days > 2
          ? _c("span", [_vm._v(_vm._s(_vm.days) + " days")])
          : _c("span", [
              _vm._v(
                " " +
                  _vm._s(_vm._f("formatTime")(_vm.hours)) +
                  ":" +
                  _vm._s(_vm._f("formatTime")(_vm.minutes)) +
                  ":" +
                  _vm._s(_vm._f("formatTime")(_vm.seconds)) +
                  " "
              ),
            ]),
      ])
    : _c("span", [_vm._v("Soon")])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }